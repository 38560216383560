import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import SEO from '../components/SEO';
import SiteTitle from '../components/SiteTitle';

import Photo from '../images/photo.jpg';
import Portfolio from '../images/tinapastore_portfolio.pdf';
import Resume from '../images/tinapastore_resume.pdf';

import styles from './landing.module.css';

const IndexPage = () => (
	<main className={ styles.container }>
		<SEO title="Home" />
		<SiteTitle
			className={ styles.header }
			size="4.25rem"
		/>
		<p className={ styles.links }>
			<Link to="/projects">projects</Link>
			<span className={ styles.linkSeparator }>&middot;</span>
			<OutboundLink
				className={ styles.portfolioLink }
				eventCategory="Download Link"
				eventLabel="View Portfolio"
				href={ Portfolio }
				rel="noreferrer noopener"
				target="_blank"
			>
				portfolio
			</OutboundLink>
			<span className={ styles.linkSeparator }>&middot;</span>
			<OutboundLink
				className={ styles.resumeLink }
				eventCategory="Download Link"
				eventLabel="View Resume"
				href={ Resume }
				rel="noreferrer noopener"
				target="_blank"
			>
					resume
			</OutboundLink>
		</p>
		<section className={ styles.content }>
			<img src={ Photo } alt="Tina Pastore" />
			<div className={ styles.info }>
				<div className={ styles.about }>
					<h4>about</h4>
					<p>My passions lie at a crossroads between environmental advocacy, sustainable planning, and community engagement and outreach.</p>
					<p>I am a problem solver, and I possess a unique combination of design skills, creativity, and eco-conscious values that I believe make me capable of affecting significant change in the field of sustainable development.</p>
				</div>
				<div className={ styles.contact }>
					<h4>contact</h4>
					<p>tina.pastore[a]gmail.com</p>
					<p>
						<OutboundLink
							eventLabel="LinkedIn"
							href="https://linkedin.com/in/tinapastore"
							target="_blank"
							rel="noreferrer noopener"
						>
							linkedin.com/in/tinapastore
						</OutboundLink>
					</p>
				</div>
			</div>
		</section>
	</main>
);

export default IndexPage;
